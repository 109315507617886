import { Link } from "gatsby"
import React from "react"
import Error404 from "../assets/images/404-error.svg"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"

const Contact = () => {
  return (
    <Layout>
      {/* <Seo title={pageMeta?.metaTitle} description={pageMeta?.metaDesc} keywords={pageMeta?.metaKeyword} /> */}
      <Navbar />
      <div className="text-center">
        <img src={Error404} alt="404-error" />
        <h1 className="mt-5">On – Snap!</h1>
        <p>
          We are unable to process this request!
          <br />
          To explore more head back to Home
        </p>
        <div className="btn-box my-3">
          <Link to="/" className="default-btn">
            <i className="flaticon-right"></i>
            Go to Home <span></span>
          </Link>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Contact
